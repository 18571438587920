import React from 'react';
import RpgIdtaLayout from "templates/rpg-idta-layout.js";
import usStateRPGMatrix from "data/rpg-map-colors.js";
import usStateRPGData from "data/rpg-map-data.js";
import {useStrapiRpgPageData} from "hooks";

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined"


const Rpg = ({location}) => {
     let thisLocationPathname = ''

     if (isBrowser) {
         thisLocationPathname = window.location.pathname
     }
     const { strapiRpgProgramPage } = useStrapiRpgPageData();
    return (
        <RpgIdtaLayout
        location={location}
        title={strapiRpgProgramPage?.title}
        highlightedResourcesData={strapiRpgProgramPage?.blocks}
        resourcesData={[...strapiRpgProgramPage?.resources, ...strapiRpgProgramPage?.videos_and_webinars, ...strapiRpgProgramPage?.series]}
        topicHeroImage={strapiRpgProgramPage?.hero_image?.background_image?.localFile}
        color="#f5f5f5"
        fixedHeroHeight={false}
        imgStyle="rpg-image"
        img={strapiRpgProgramPage?.image?.localFile}
        imgAltText={strapiRpgProgramPage?.image?.alternativeText ?? ""}
        customCurrentPage={strapiRpgProgramPage?.title}
        description={strapiRpgProgramPage?.description}
        pageDescription={strapiRpgProgramPage?.hero_image?.description?.data?.childMarkdownRemark?.html}
        keywords={strapiRpgProgramPage?.keywords}
        pathname={thisLocationPathname}
        usStateData={usStateRPGData}
        usStateMatrix={usStateRPGMatrix}
        includeDropDown={true}
        dropDownName="rpg-states"
        dropDownLabel="To view a state profile, select a state or territory"
        >
            {strapiRpgProgramPage?.text &&
                <div className="padding-top-0"
                dangerouslySetInnerHTML={{
                __html: strapiRpgProgramPage?.text?.data?.childMarkdownRemark?.html,
                }}/>
            }
    </RpgIdtaLayout>
    )
};

export default Rpg;
